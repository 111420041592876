import axios from "axios";
import i18n from "./i18n";

// src/setupAxios.js
// Setup defaults and response interceptor for the axios HTTP library.
// Applications should not modify this file, and any changes to message strings in src/locals/en/common.json
//  should be made to the common.json file in the as-frontend-starter project.
export const setupAxios = () => {
  // If the base URL contains DEPLOY_ENV, update with the deployment environment from public/env.js
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL.replace(
    "DEPLOY_ENV",
    window.DEPLOY_ENV
  );
  axios.defaults.timeout = process.env.REACT_APP_API_TIMEOUT;

  /*
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJsdnVqVFRRX1RZVUpoMDBKU2RKQ0dHOXpYcElSa3VPWWk1am5iZzdVWHNRIn0.eyJleHAiOjE2MDgzMzkxNzcsImlhdCI6MTYwODMzODg3NywiYXV0aF90aW1lIjoxNjA4MzM4NDU4LCJqdGkiOiJmYTNhNmI1Ny0yMzI3LTRkYTktYjFlNS1jMjVlMjU1YjA5NDUiLCJpc3MiOiJodHRwczovL2lkcC1wcm94eS5tZWQuc3RhbmZvcmQuZWR1L2F1dGgvcmVhbG1zL21lZC1zdSIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiIyMjc2MmFhNC0wZGFlLTQ2MzQtOTg4Yy0xZTkwZTk2YjRkYjciLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJ0cmFpbmluZy1ncmFudHMtcG9ydGFsLWFwaS1vaWRjLWRldiIsInNlc3Npb25fc3RhdGUiOiI3N2NjOGU5ZS0wMTkxLTRkMGEtYTE1My1kODdmOWVlOGU3NGIiLCJhY3IiOiIwIiwiYWxsb3dlZC1vcmlnaW5zIjpbIiIsImh0dHBzOi8vdHJhaW5pbmctZ3JhbnRzLXBvcnRhbC1hcGktZGV2Lm1lZC5zdGFuZm9yZC5lZHUiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImRldl90cmFpbmluZy1ncmFudHMuc3VwZXItYWRtaW4iLCJvZmZsaW5lX2FjY2VzcyIsImRldl90cmFpbmluZy1ncmFudHMuc3lzdGVtLWFkbWluIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im5vcm1hbGl6ZWQtb3BlbmlkIiwicHJpbmNpcGFsIjoiY2JyaWNrZXJAc3RhbmZvcmQuZWR1IiwidWlkIjoiY2JyaWNrZXIiLCJzdXJuYW1lIjoiYnJpY2tlciIsImRpc3BsYXlOYW1lIjoiQ2hyaXMgQnJpY2tlciIsImdpdmVuTmFtZSI6ImNocmlzIiwiZW1haWwiOiJjYnJpY2tlckBzdGFuZm9yZC5lZHUifQ.cAv5bSUP_XttvCzfW1F7sMhYM42lPhuL9zhosqO1O6vN1ij4mruKmVWBaFDLeu6lc_xOWaWnL5SyLmXU6RLUkTqQ-_c14hp_oGf9KWtZfaOUtxZrWy6dKqEwzTUWYCudsRiUSoiCMIm1PXYCHgmucSir83j-nG0j7SK93Vki965IXR0QWQh8r-jEb_w6CBr3b6sM6VH0AJKrW0iVryjwuzQVYB8aO8f3Ea4-_IRYawCvAmLjOZ3_-59Z6GAUraSSZR58mIyldLRMmxGSn5D1FGu3u7F7HLK6OyAoqtg-9_RvDpnPrOLtCyjCGlBpGhxfRSNrUe-8Av_uB8KIscrmdw`;
  */

  // For any API error, set a default error message which the service can either accept or override
  axios.interceptors.response.use(
    (response) => {
      // Any status code of 2xx causes this function to trigger
      // Return the unmodified response back to the service for handling
      return response;
    },
    (error) => {
      // Any results other than status code 2xx causes this function to trigger
      if (error.response) {
        // The request was made and the server responded with a status code other than 2xx
        // HTTP 4xx errors
        if (
          error.response.data.detail &&
          Array.isArray(error.response.data.detail.properties) &&
          !!error.response.data.detail.properties[0]
        ) {
          error.message = error.response.data.detail.properties
            .map((entry) => {
              return !!entry.message
                ? entry.message
                : i18n.t(
                    `common:apiErrorDefaultMessages.${error.response.data.status}.${error.response.data.code}`,
                    entry
                  );
            })
            .join(", ");
        }
        // HTTP 5xx errors
        else {
          // Address missing properties
          if (error.response.data.error === "Internal Server Error") {
            error.response.data.code = "SERVER_ERROR";
          } else if (error.response.data === "") {
            error.response.data = {
              status: error.response.status,
              code: error.response.statusText,
            };
          }
          error.message = i18n.t(
            `common:apiErrorDefaultMessages.${error.response.data.status}.${error.response.data.code}`,
            error.response.data
          );
        }
        error.status = error.response.data.status;
        error.code = error.response.data.code;
      } else if (error.request) {
        // The request was made but no response was received
        // error.request is an instance of XMLHttpRequest in the browser
        error.message = i18n.t(
          "common:apiErrorDefaultMessages.REQUEST_TIMEOUT"
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        error.message = i18n.t("common:apiErrorDefaultMessages.REQUEST_ERROR");
      }

      // Return the default error message to the service in error.message
      // Also return error.status and error.code if the error came from the backend
      return Promise.reject(error);
    }
  );
};
