import React from "react";
import { useTranslation } from "react-i18next";
import { useAlertContext } from "@stanford-tds/as-components";
import { useTrainee } from "../services/traineeService";
import {
  ASHeader,
  ASMain,
  ASAlert,
  ASFooter,
} from "@stanford-tds/as-components";
import { Grid, CircularProgress } from "@material-ui/core";
import { ErrorPage } from "../components/ErrorPage";
import ErrorBoundary from "./ErrorBoundary";
import { TraineePortal } from "../components/TraineePortal";

// src/components/AppPage.js
// AppPage is the top-level component that can be customized in your single-page application.
const AppPage = () => {
  const { t } = useTranslation();
  const { alert } = useAlertContext();

  /**
   * 1) Start loading
   * 2) get user info
   * 3) Remove loading
   * 4) if success
   *      load app
   * 5) if failure
   *      display error
   */
  const { traineeData, setTraineeData, userToken } = useTrainee();

  return (
    <>
      <ASHeader
        sitenameTitle={t("app.title")}
        sitenameSubtitle={t("app.subtitle")}
        displayName=""
      />
      <ASMain>
        {alert.exists && <ASAlert />}
        {!!traineeData?.uuid ? (
          <ErrorBoundary>
            <TraineePortal
              traineeData={traineeData}
              setTraineeData={setTraineeData}
              userToken={userToken}
            />
          </ErrorBoundary>
        ) : (
          <Grid
            container
            spacing={0}
            align="center"
            justify="center"
            direction="column"
            style={{ minHeight: "100%" }}
          >
            <Grid item>
              {!!traineeData?.error ? (
                <ErrorPage httpErrorCode={traineeData?.error} />
              ) : (
                <CircularProgress />
              )}
            </Grid>
          </Grid>
        )}
      </ASMain>
      <ASFooter
        currentYear={new Date().getFullYear()}
        buildVersion={process.env.REACT_APP_VERSION}
      />
    </>
  );
};

export default AppPage;
