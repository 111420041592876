import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "@material-ui/core/styles";
import { TraineeProvider } from "./services/traineeService";
import { parseUrl } from "./utils/utils";
import {
  ASAlertProvider,
  ASAppPage,
  ASTheme,
} from "@stanford-tds/as-components";
import AppPage from "./components/AppPage";
import RedirectPage from "./components/RedirectPage";

// src/App.js
// App component containing the standard components and configuration for all applications.
// Applications should modify the AppPage component in components/AppPage and should leave this file unmodified.
const App = () => {
  const { t } = useTranslation();

  // Get the User ID from the path
  const { pathname } = parseUrl(window.location.href);
  const userToken = pathname.substring(1);

  return !!!userToken ? (
    <RedirectPage />
  ) : (
    <div>
      <Helmet>
        <title>{t("app.title")}</title>
        <meta name="description" content={t("app.description")} />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,300,600,700,200italic,300italic,400italic,700italic|Fjord+One"
        />
      </Helmet>
      <ThemeProvider theme={ASTheme}>
        <ASAlertProvider>
          <TraineeProvider userToken={userToken}>
            <ASAppPage>
              <AppPage />
            </ASAppPage>
          </TraineeProvider>
        </ASAlertProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
