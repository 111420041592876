import React from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Grid, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { AboutYouStep } from "./AboutYouStep";
import { TrainingAtStanfordStep } from "./TrainingAtStanfordStep";
import { TrainingSupportStep } from "./TrainingSupportStep";
import { YourEmploymentStep } from "./YourEmploymentStep";
import { SubsequentGrantsStep } from "./SubsequentGrantsStep";
import { SubmittedStep } from "./SubmittedStep";
import { usePortalStyles } from "./PortalStyles";
import { SteppedForm } from "./SteppedForm";

export const TraineePortal = ({ traineeData, setTraineeData, userToken }) => {
  //console.log("Rendering TraineePortal", traineeData);
  const [activeStep, setActiveStep] = React.useState(0);
  const classes = usePortalStyles();
  const { t } = useTranslation();
  const extraSmallScreen = useMediaQuery("(max-width:360px)");

  const steps = [
    t("steps.aboutYou.stepperLabel"),
    t("steps.trainingAtStanford.stepperLabel"),
    t("steps.trainingSupport.stepperLabel"),
    t("steps.yourEmployment.stepperLabel"),
    t("steps.subsequentGrants.stepperLabel"),
  ];

  const getStepComponent = ({ activeStep: stepIndex, ...props }) => {
    let StepComponent;
    switch (stepIndex) {
      case 1:
        StepComponent = TrainingAtStanfordStep;
        break;
      case 2:
        StepComponent = TrainingSupportStep;
        break;
      case 3:
        StepComponent = YourEmploymentStep;
        break;
      case 4:
        StepComponent = SubsequentGrantsStep;
        break;
      case 0:
      default:
        StepComponent = AboutYouStep;
        break;
    }
    return <StepComponent {...props} />;
  };

  //console.log("Steps:", activeStep, steps.length);

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      direction="column"
      classes={{ container: classes.containerWrapper }}
      spacing={extraSmallScreen ? 0 : 2}
    >
      {activeStep === steps.length ? (
        <SubmittedStep />
      ) : (
        <Grid item xs={12} sm={10} md={8} lg={5} xl={4}>
          <Grid container justify="center">
            <Grid item classes={{ item: classes.itemWrapper }}>
              <Typography
                variant={"h1"}
                id="rootTitle"
                classes={{ root: classes.headerTitleRoot }}
              >
                {t("common.portalName")}
              </Typography>
            </Grid>
            <Grid item classes={{ item: classes.stepperWrapper }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item classes={{ item: classes.formContentWrapper }}>
              <SteppedForm
                setActiveStep={setActiveStep}
                isFirstStep={activeStep === 0}
                isLastStep={activeStep === steps.length - 1}
                setTraineeData={setTraineeData}
                userToken={userToken}
              >
                {getStepComponent({ activeStep, traineeData })}
              </SteppedForm>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
